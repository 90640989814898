import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import styled from "styled-components";
import Logo from "../images/svg/cap-e-logo.svg";

const Wrapper = styled.div`
  font-family: "Noto Sans", sans-serif;

  #wrapper {
    background: #f6f6f6;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 32px 32px 32px;
  }

  #nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;

    .cap-e-brand {
      width: 175px;
    }
  }

  #banner {
    text-align: center;
    margin: 60px 0 0 0;

    h1 {
      color: #003347;
      font-size: 2.3rem;
    }

    h2 {
      color: #0092b6;
      font-size: 1.2rem;
    }

    .top-description {
      margin: 40px 0 0 0;

      p {
        font-weight: 300;
        font-size: 1rem;
        max-width: 680px;
        margin: 0 auto 26px auto;
        line-height: 1.6;
        color: #003347;
      }

      ul,
      ol {
        margin: 30px 0;

        li {
          font-weight: 300;
          font-size: 1rem;
          max-width: 680px;
          margin: 0 auto 13px auto;
          line-height: 1.6;
          color: #003347;
        }
      }

      ol {
        li {
          list-style-type: decimal;
        }
      }

      ul {
        li {
          list-style-type: disc;
        }
      }

      a {
        color: #0092b6;
      }
    }

    .mailto {
      margin: 46px auto 0 auto;
      color: #0092b6;

      a {
        color: #0092b6;
        text-decoration: underline;
        text-underline-position: under;
        transition: 0.1s opacity ease-out;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  #form {
    background: #f6f6f6;
    display: block;
    margin: 0 auto;
    padding: 30px 0 0 0;

    iframe {
      text-align: center;
      display: block;
      margin: 0 auto;
      width: 100%;
      height: ${(props) =>
        props.google_form_height ? `${props.google_form_height}px` : `100vh`};
    }
  }

  .submitted-txt {
    text-align: center;
    font-weight: 300;
    font-size: 1rem;
    margin: 0 0 80px 0;
    line-height: 1.6;
    color: #003347;
    padding: ${(props) => (props.google_form_height ? "0" : "30px 0 0 0")};
  }

  .submitted-txt a {
    color: #0092b6;
    transition: 0.3s opacity ease-out;
  }

  .submitted-txt a:hover {
    opacity: 0.75;
  }

  #footer {
    text-align: center;

    svg {
      width: 175px;
    }

    p {
      font-weight: 300;
      font-size: 1rem;
      max-width: 650px;
      margin: 46px auto 23px auto;
      line-height: 1.6;
      color: #003347;
    }

    a {
      color: #003347;
      font-weight: 600;
      -webkit-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;

      &:hover {
        color: #0092b6;
      }
    }
  }

  #end {
    background: #003347;
    padding: 32px 32px;

    p {
      text-align: center;
      line-height: 1.6;
    }

    p,
    a {
      color: #fff;
      font-size: 0.875rem;
    }
  }

  @media (min-width: 620px) {
    #wrapper {
      padding: 50px 52px 52px 52px;
    }

    #banner {
      h1 {
        font-size: 3.3rem;
      }

      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.1rem;
      }
    }

    #end {
      background: #003347;
      padding: 32px 52px;
    }
  }
`;

const SingleFormPage = ({ data }) => {
  const currentYear = new Date().getFullYear();

  const {
    email,
    google_form_height,
    google_form_url,
    subtitle,
    title,
    top_description,
  } = data.main.data;

  return (
    <Layout>
      <Seo title={title.text || "Salesforce"} />
      <Wrapper google_form_height={google_form_height}>
        <div id="submitted"></div>
        <div id="wrapper">
          <nav id="nav">
            <a
              className="cap-e-brand"
              href="https://www.capital-e.com.au"
              target="_blank"
            >
              <Logo />
            </a>
          </nav>
          <section id="banner">
            <h1>{title.text || "Salesforce"}</h1>
            <h2>{subtitle.text || "Delivery Address Confirmation"}</h2>
            <div
              className="top-description"
              dangerouslySetInnerHTML={{ __html: top_description.html }}
            />

            <p className="mailto">
              If you can't see the form below, please{" "}
              <a href={`mailto:${email || "abigail@capital-e.com.au"}`}>
                click here
              </a>
            </p>
          </section>

          <section id="form" className="hvdm">
            <iframe src={google_form_url} frameBorder="0">
              Loading…
            </iframe>
          </section>
          <p className="submitted-txt">
            Once you have submitted your details, please{" "}
            <a href="#submitted">click</a> here
          </p>
          <footer id="footer">
            <a href="https://www.capital-e.com.au" target="_blank">
              <Logo />
            </a>

            <p>
              CAPITAL-e is the Engagement Agency. We provide creative marketing
              programs, events and experiences that help you build relationships
              with those who matter most. <br />
              To find out more about us please visit our website.
            </p>

            <a href="https://www.capital-e.com.au" target="_blank">
              capital-e.com.au
            </a>
          </footer>
        </div>
        <div id="end">
          <p>
            &copy; {currentYear} - CAPITAL-e Marketing & Events Pty Ltd ABN: 94
            149 821 465 |
            <a
              href="https://www.capital-e.com.au/wp-content/uploads/2020/10/Capital-e-Website-Terms-and-Conditions-20.10.20.pdf"
              target="_blank"
            >
              Terms & Conditions
            </a>
            |
            <a
              href="https://www.capital-e.com.au/wp-content/uploads/2020/10/Capital-e-Privacy-Policy-20.10.20.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default SingleFormPage;

export const data = graphql`
  query ($id: String!) {
    main: prismicFormPage(id: { eq: $id }) {
      data {
        google_form_url
        subtitle {
          text
        }
        title {
          text
        }
        top_description {
          html
        }
        email
        google_form_height
      }
    }
  }
`;
